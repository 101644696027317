const Option = props => (
  <button
    className="option"
    onClick={props.onClick}
    tabIndex={!props.active ? -1 : null}
    aria-disabled={!props.active ? true : null}
  >
    {props.children}
  </button>
)

export default Option
