import { createContext } from 'react'
import { useLocalStorage, useActiveText } from './hooks'
import api from '../../data/api.json'

export const State = createContext()
export const ActiveText = createContext()

const Context = ({children}) => {
  const [state, setState] = useLocalStorage('state', {
    api,
    input: {
      bible: 1,
      book: 1,
      chapter: 1
    },
    output: {},
    search: {
      input: '',
      output: {},
      page: 1,
      pages: 1
    },
    active: {
      search: false,
      input: false,
      verses: {},
      control: {}
    }
  })

  const activeText = useActiveText(state)

  return (
    <State.Provider value={[state, setState]}>
      <ActiveText.Provider value={activeText}>
        {children}
      </ActiveText.Provider>
    </State.Provider>
  )
}

export default Context
