import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import Context from './utils/context'
import App from './app'

import './styles/*'

const root = createRoot(document.getElementById('app'))

root.render(
  <StrictMode>
    <Context>
      <App />
    </Context>
  </StrictMode>
)

if (location.hostname !== 'localhost') {
  navigator.serviceWorker?.register('/sw.js')
} else {
  navigator.serviceWorker?.getRegistration()
  .then(sw => sw?.unregister())
}
