import { useContext } from 'react'
import { State, ActiveText } from '../utils/context'
import Button from '../components/button'
import ArrowLeft from '../icons/arrow-left'
import ArrowRight from '../icons/arrow-right'
import Copy from '../icons/copy'
import Share from '../icons/share'
import Search from '../icons/search'
import Close from '../icons/close'

const Buttons = () => {
  const [{api, input, output, search, active}, setState] = useContext(State)
  const activeText = useContext(ActiveText)

  const onChange = value => active.search ? onSubmit(search.page + value) : setState(state => ({
    ...state, 
    input: {
      ...state.input,
      chapter: state.input.chapter + value
    }
  }))

  const onClick = () => {
    navigator.share
      ? navigator.share({text: activeText}).then(() => setState(state => ({
        ...state,
        active: {
          ...state.active,
          verses: {}
        }
      }))).catch(() => {})
      : navigator.clipboard.writeText(activeText).then(() => setState(state => ({
        ...state,
        active: {
          ...state.active,
          verses: {}
        }
      })))
  }

  const onSearch = () => setState(state => ({
    ...state,
    active: {
      ...state.active,
      search: !state.active.search,
      input: !state.active.search
    }
  }))

  const onSubmit = async value => {
    setState(state => ({
      ...state,
      active: {
        ...state.active,
        input: false,
        verses: {}
      },
      search: {
        ...state.search,
        output: {}
      }
    }))

    if (search.input) {
      const resp = await fetch(`/api/search/${input.bible}/${search.input}?page=${value}`, {
        cache: 'force-cache'
      })
      const data = await resp.json()

      setState(state => ({
        ...state,
        search: {
          ...state.search,
          output: data,
          page: +resp.headers.get('X-Page'),
          pages: +resp.headers.get('X-Pages')
        }
      }))
    }
  }

  return (
    <div className="buttons">
      <Button
        active={active.search ? search.page > 1 : input.chapter > 1}
        disabled={active.search ? !Object.keys(search.output).length : !Object.keys(output).length}
        onClick={() => onChange(-1)}
        aria-label="Prev Chapter"
      >
        <ArrowLeft />
      </Button>
      <Button
        active={true}
        onClick={() => activeText ? onClick() : onSearch()}
        aria-label={activeText
          ? navigator.share
            ? 'Share Selected'
            : 'Copy Selected'
          : active.search
            ? 'Close'
            : 'Search'
        }
      >
        {activeText
          ? navigator.share
            ? <Share />
            : <Copy />
          : active.search
            ? <Close className="button__close" />
            : <Search />
        }
      </Button>
      <Button
        active={active.search ? search.page < search.pages : input.chapter < api.chapters[input.book]}
        disabled={active.search ? !Object.keys(search.output).length : !Object.keys(output).length}
        onClick={() => onChange(+1)}
        aria-label="Next Chapter"
      >
        <ArrowRight />
      </Button>
    </div>
  )
}

export default Buttons
