import { useContext, useEffect, useRef } from 'react'
import { State } from '../utils/context'

const Form = props => {
  const [{input, search, active}, setState] = useContext(State)
  const ref = useRef()

  const onChange = e => setState(state => ({
    ...state,
    search: {
      ...state.search,
      input: e.target.value
    }
  }))

  const onSubmit = async e => {
    e.preventDefault()

    setState(state => ({
      ...state,
      active: {
        ...state.active,
        input: false,
        verses: {}
      },
      search: {
        ...state.search,
        output: {},
        page: 1,
        pages: 1
      }
    }))

    if (search.input) {
      const resp = await fetch(`/api/search/${input.bible}/${search.input}`, {
        cache: 'force-cache'
      })
      const data = await resp.json()

      setState(state => ({
        ...state,
        search: {
          ...state.search,
          output: data,
          page: +resp.headers.get('X-Page'),
          pages: +resp.headers.get('X-Pages')
        }
      }))
    }
  }

  useEffect(() => {
    active.search && active.input ? ref.current.focus() : ref.current.blur()
  }, [active.search, active.input])

  return (
    <form
      className={`form${active.search ? '' : ' hidden'}`}
      onSubmit={onSubmit}
    >
      <input
        className="form__input"
        ref={ref}
        type="search"
        name={props.name}
        value={search.input}
        onChange={onChange}
        aria-label={props.label}
      />
    </form>
  )
}

export default Form
