import { useContext } from 'react'
import { State, ActiveText } from '../utils/context'
import { useEventListener } from '../utils/hooks'

const Keyboard = () => {
  const [{api, input, output, search, active}, setState] = useContext(State)
  const activeText = useContext(ActiveText)

  const onChange = value => active.search ? onSubmit(search.page + value) : setState(state => ({
    ...state, 
    input: {
      ...state.input,
      chapter: state.input.chapter + value
    }
  }))

  const onClick = () =>  navigator.clipboard.writeText(activeText).then(() => setState(state => ({
    ...state,
    active: {
      ...state.active,
      verses: {}
    }
  })))

  const onSubmit = async value => {
    setState(state => ({
      ...state,
      active: {
        ...state.active,
        input: false,
        verses: {}
      },
      search: {
        ...state.search,
        output: {}
      }
    }))

    if (search.input) {
      const resp = await fetch(`/api/search/${input.bible}/${search.input}?page=${value}`, {
        cache: 'force-cache'
      })
      const data = await resp.json()

      setState(state => ({
        ...state,
        search: {
          ...state.search,
          output: data,
          page: +resp.headers.get('X-Page'),
          pages: +resp.headers.get('X-Pages')
        }
      }))
    }
  }

  useEventListener(window, 'keydown', e => {
    if (e.ctrlKey || e.metaKey) {
      switch (e.code) {
        case 'KeyA': !active.input && setState(state => ({
            ...state,
            active: {
              ...state.active,
              verses: Object.fromEntries(Object.keys(state.active.search ? state.search.output : state.output).map(id => [id, true]))
            }
          }));
        break
        case 'KeyC': activeText && onClick(); break
        case 'KeyF': e.preventDefault() || setState(state => ({
            ...state,
            active: {
              ...state.active,
              search: true,
              input: !state.active.input
            }
          }));
        break
      }
    }
    else {
      switch (e.code) {
        case 'ArrowLeft': (active.search ? Object.keys(search.output).length && search.page > 1 : Object.keys(output).length && input.chapter > 1) && onChange(-1); break
        case 'ArrowRight': (active.search ? Object.keys(search.output).length && search.page < search.pages : Object.keys(output).length && input.chapter < api.chapters[input.book]) && onChange(+1); break
        case 'Escape': e.preventDefault() || setState(state => ({
            ...state,
            active: {
              search: state.active.search && (state.active.input || Object.values(state.active.verses).includes(true)),
              input: false,
              verses: state.active.input || Object.values(state.active.control).includes(true) ? state.active.verses : {},
              control: {}
            }
          }));
        break
      }
    }
  }, [input, output, search, active, activeText])

  return null
}

export default Keyboard
