import { useEffect, useRef, useContext } from 'react'
import { State } from '../utils/context'
import Option from './option'

const Options = props => {
  const options = useRef()
  const [, setState] = useContext(State)

  const onChange = value => {
    setState(state => ({
      ...state,
      input: {
        ...state.input,
        chapter: value.hasOwnProperty('book') ? 1 : state.input.chapter,
        ...value
      }
    }))
  }

  useEffect(() => {
    options.current.scrollTo({top: props.value * 50})
  }, [props.active])

  return (
    <div
      className={`options${props.active ? ' active' : ''}`}
      ref={options}
    >
      {Object.entries(props.options).map(([id, option]) => (
        <Option
          key={id}
          active={props.active}
          onClick={() => onChange({[props.name]: +id})}
        >
          {option}
        </Option>
      ))}
    </div>
  )
}

export default Options
