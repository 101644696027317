import { useContext } from 'react'
import { State } from '../utils/context'
import Options from './options'
import ArrowDown from '../icons/arrow-down'

const Select = props => {
  const [{input, output, active}, setState] = useContext(State)

  const onClick = () => setState(state => ({
    ...state,
    active: {
      ...state.active,
      control: {
        [props.name]: !state.active.control[props.name]
      }
    }
  }))

  return (
    <div className={`select${active.search ? ' hidden' : ''}`}>
      <button
        className={`select__value${active.control[props.name] ? ' active' : ''}${!Object.keys(output).length ? ' disabled' : ''}`}
        onClick={onClick}
        tabIndex={!Object.keys(output).length ? -1 : null}
        aria-disabled={!Object.keys(output).length ? true : null}
      >
        <div className="select__text">{props.options[input[props.name]]}</div>
        <ArrowDown className="select__icon" />
      </button>
      <Options
        name={props.name}
        options={props.options}
        value={input[props.name]}
        active={active.control[props.name]}
        onChange={props.onChange}
      />
    </div>
  )
}

export default Select
