const Button = ({active, disabled, ...props}) => (
  <button
    className={`button${active ? ' active' : ''}${disabled ? ' disabled' : ''}`}
    tabIndex={!active || disabled ? -1 : null}
    aria-disabled={!active || disabled ? true : null}
    {...props}
  />
)

export default Button
