import { useContext } from 'react'
import { State } from '../utils/context'
import Select from '../components/select'
import Form from '../components/form'

const Controls = () => {
  const [{api, input}] = useContext(State)
  const options = Object.fromEntries([...Array(api.chapters[input.book]).keys()].map(id => [id+1, id+1]))
  
  return (
    <div className="controls">
      <Select name="bible" options={api.bibles} label="Select Bible" />
      <Select name="book" options={api.books} label="Select Book" />
      <Select name="chapter" options={options} label="Select Chapter" />
      <Form name="search" label="Search" />
    </div>
  )
}

export default Controls
