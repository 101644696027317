import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import Context from './utils/context'
import App from './app'

import './styles/_button.scss'
import './styles/_buttons.scss'
import './styles/_controls.scss'
import './styles/_form.scss'
import './styles/_option.scss'
import './styles/_options.scss'
import './styles/_root.scss'
import './styles/_select.scss'
import './styles/_verse.scss'
import './styles/_verses.scss'

const root = createRoot(document.getElementById('app'))

root.render(
  <StrictMode>
    <Context>
      <App />
    </Context>
  </StrictMode>
)

navigator.serviceWorker?.register('/sw.js')
