import { useState, useEffect } from 'react'

export const useLocalStorage = (key, initialValue) => {
  const getValue = () => {
    try {
      return JSON.parse(localStorage.getItem(key))
    }
    catch {
      return initialValue
    }
  }

  const [value, setValue] = useState(getValue() ?? initialValue)

  useEffect(() => localStorage.setItem(key, JSON.stringify(value)), [value])

  return [value, setValue]
}

export const useEventListener = (element, event, listener, dependencies) => {
  return useEffect(() => {
    element?.addEventListener(event, listener)

    return () => element?.removeEventListener(event, listener)
  }, dependencies)
}

export const useActiveText = ({api, input, output, search, active}) => {
  let text, verses

  if (active.search && Object.keys(search.output).length) {
    text = Object.entries(search.output).filter(([id]) => active.verses[id]).map(([, match]) => (
      `${match.text} (${api.books[match.book]} ${match.chapter}:${match.verse})`
    ))

    return `${text.join(' ')}`
  }
  else {
    text = Object.entries(output).filter(([id]) => active.verses[id]).map(([, match]) => match)
    verses = Object.keys(output).filter(id => active.verses[id]).map((i, id, arr) => {
      if ((id == arr.length - 1) && (arr[id + 1] - i != 1) && (i - arr[id - 1] != 1)) return i
      else if ((id == arr.length - 1) && (arr[id + 1] - i != 1) && (i - arr[id - 1] == 1)) return `-${i}`
      else if ((id != arr.length - 1) && (arr[id + 1] - i != 1) && (i - arr[id - 1] != 1)) return `${i},`
      else if ((id != arr.length - 1) && (arr[id + 1] - i != 1) && (i - arr[id - 1] == 1)) return `-${i},`
      else if ((arr[id + 1] - i == 1) && (i - arr[id - 1] != 1)) return i
      else if ((arr[id + 1] - i == 1) && (i - arr[id - 1] == 1)) return ''
    })

    if (!Object.values(active.verses).includes(true)) {
      return null
    }
    else if (!Object.values(active.verses).includes(false) && Object.keys(active.verses).length == Object.keys(output).length) {
      return `${text.join(' ')} (${api.books[input.book]} ${input.chapter})`
    }
    else {
      return `${text.join(' ')} (${api.books[input.book]} ${input.chapter}:${verses.join('')})`
    }
  }
}
