import { useContext } from 'react'
import { State } from '../utils/context'
import Verse from '../components/verse'

const Verses = () => {
  const [{api, output, search, active}, setState] = useContext(State)

  const onClick = id => setState(state => ({
    ...state,
    active: {
      ...state.active,
      verses: {
        ...state.active.verses,
        [id]: !state.active.verses[id]
      }
    }
  }))

  return (
    <div className="verses">
      {active.search
        ? Object.entries(search.output).map(([id, match]) => (
          <Verse
            key={id}
            active={active.verses[id]}
            onClick={() => onClick(id)}
            id={`${api.books[match.book]} ${match.chapter}:${match.verse}`}
          >
            {match.text}
          </Verse>
        ))
        : Object.entries(output).map(([id, text]) => (
          <Verse
            key={id}
            active={active.verses[id]}
            onClick={() => onClick(id)}
            id={id}
          >
            {text}
          </Verse>
        ))
      }
    </div>
  )
}

export default Verses
