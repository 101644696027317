import { useEffect, useRef, useContext } from 'react'
import { State } from './utils/context'
import Controls from './blocks/controls'
import Verses from './blocks/verses'
import Buttons from './blocks/buttons'
import Keyboard from './blocks/keyboard'

const App = () => {
  const self = useRef()
  const [{input, output, active}, setState] = useContext(State)

  useEffect(() => {
    if (self.current || !Object.keys(output).length) {
      setState(state => ({
        ...state,
        output: {},
        active: {
          ...state.active,
          verses: {}
        }
      }))

      scrollTo({top: 0})

      fetch(`/api/read/${input.bible}/${input.book}/${input.chapter}`, {
        cache: 'force-cache'
      })
      .then(resp => resp.json())
      .then(data => setState(state => ({
        ...state,
        output: data
      })))
    }
  }, [input])

  useEffect(() => {
    if (self.current) {
      setState(state => ({
        ...state,
        active: {
          ...state.active,
          control: {}
        }
      }))
    }
  }, [active.verses])

  useEffect(() => {
    self.current = true
  }, [])

  return (
    <>
      <Controls />
      <Verses />
      <Buttons />
      <Keyboard />
    </>
  )
}

export default App
